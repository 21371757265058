import React, { useState, useRef, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toastify css
import mainImage from './panik.png';
import { FaCopy } from "react-icons/fa";
import { FaSave } from "react-icons/fa";
import { FaShareAlt } from "react-icons/fa"; // for a share icon

const MemeGenerator = () => {
    const [topText, setTopText] = useState("");
    const [bottomText, setBottomText] = useState("");
    const canvasRef = useRef(null);
    const imageRef = useRef(null);

    // Draw the image and text on the canvas
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const image = imageRef.current;

        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // Set background to white
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // When image loads, draw it on the canvas
        image.onload = () => {
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
            // Set styles for the text
            ctx.fillStyle = 'black';  // Text color
            ctx.font = '48px Arial';
            ctx.textAlign = 'center';
            // Draw the text
            ctx.fillText(topText, canvas.width / 2, 40);
            ctx.fillText(bottomText, canvas.width / 2, canvas.height - 15);
        };

        // If the image is already loaded (cached), draw immediately
        if (image.complete) {
            image.onload();
        }
    }, [topText, bottomText]); // Redraw when texts change

    useEffect(() => {
        imageRef.current.src = mainImage; // Set image source
    }, []); // This effect runs once on mount

    const handleTopTextChange = (e) => setTopText(e.target.value);
    const handleBottomTextChange = (e) => setBottomText(e.target.value);

    const handleSave = () => {
        if (canvasRef.current) {
            const image = canvasRef.current.toDataURL("image/png");
            const link = document.createElement('a');
            link.download = 'my-meme.png';

            // This try-catch block will handle user actions and errors
            try {
                link.href = image;
                link.click();
                toast.success("Meme saved successfully!"); // Success toast
            } catch (error) {
                toast.error("Failed to save meme."); // Error toast
            }
        } else {
            toast.error("No canvas found."); // Error toast if canvas doesn't exist
        }
    };

    const handleCopy = async () => {
        if (canvasRef.current) {
            const image = await fetch(canvasRef.current.toDataURL())
                .then(res => res.blob());
            try {
                await navigator.clipboard.write([new ClipboardItem({ 'image/png': image })]);
                toast.success("Meme copied to clipboard!"); // Display success toast
            } catch (err) {
                toast.error("Failed to copy meme."); // Display error toast
            }
        }
    };

    const handleShare = async () => {
        if (navigator.share) {
            try {
                const canvas = canvasRef.current;
                canvas.toBlob(async function (blob) {
                    const file = new File([blob], 'meme.png', { type: 'image/png' });
                    await navigator.share({
                        files: [file],
                        title: 'Check out my meme!',
                        text: 'I created this meme. Enjoy!'
                    });
                    toast.success("Meme shared successfully!");
                }, 'image/png');
            } catch (error) {
                toast.error("Failed to share meme: " + error);
            }
        } else {
            toast.error("Web Share API is not supported in your browser.");
        }
    };


    return (
        <>
            <div className="p-8 md:p-18 md:flex md:gap-20 justify-center items-center">
                <form className="meme-form mb-8 flex flex-col items-center" onSubmit={(e) => e.preventDefault()}>
                    <input
                        type='text'
                        name='topText'
                        placeholder='Top Text'
                        value={topText}
                        onChange={handleTopTextChange}
                        className="mb-4 p-2 border border-black rounded"
                    />
                    <input
                        type='text'
                        name='bottomText'
                        placeholder='Bottom Text'
                        value={bottomText}
                        onChange={handleBottomTextChange}
                        className="mb-4 p-2 border border-black rounded"
                    />
                    <div className="flex gap-4">

                        <button type="button" onClick={handleSave} className="bg-black border text-white hover:bg-white hover:text-black font-bold py-2 px-4 rounded">
                            <FaSave />
                        </button>
                        <button type="button" onClick={handleCopy} className="bg-black border text-white hover:bg-white hover:text-black font-bold py-2 px-4 rounded">
                            <FaCopy />
                        </button>
                        <button type="button" onClick={handleShare} className="bg-black border text-white hover:bg-white hover:text-black font-bold py-2 px-4 rounded">
                            <FaShareAlt />
                        </button>
                    </div>
                </form>
                <div className="meme-inner relative border-slate-700 border">
                    <canvas ref={canvasRef} width={600} height={500} className="w-full h-auto"></canvas>
                    <img ref={imageRef} src={mainImage} alt='Meme' className="hidden" onLoad={() => { }} />
                </div>

            </div>
            <ToastContainer />

        </>
    );
}

export default MemeGenerator;
