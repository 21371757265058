import logo from './logo.svg';
import './App.css';
import MemeGenerator from './MemeGenerator';

function App() {
  return (
    <div className="App h-100 md:h-[100vh] pt-18 flex flex-col items-center justify-center">
      <h1 className="text-2xl font-bold">DON'T $PANIK. HERE IS OUR MEME GENERATOR.</h1>
      <MemeGenerator/>
      <small>developped by <a className="font-bold link text-underline" href="https://twitter.com/PanikSol">$PANIK</a> team</small>
    </div>
  );
}

export default App;
